import { makeStyles } from '@material-ui/styles';
import { ORANGE } from '../../constants/colors';
import { PHONE_MAX, TABLET_MAX } from '../../constants/breakpoints';

const useStyles = makeStyles({
  root: {
    width: '90%',
    maxWidth: 1440,
    margin: '0 auto',
  },
  section: {
    margin: '90px 0',
    [PHONE_MAX]: {
      margin: '0',
    },
  },
  textImageContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: 50,
  },
  image: {
    flex: '0 1 350px',
    width: '100%',
    maxWidth: 650,
    borderRadius: 8,
    boxShadow: '0 10px 30px rgba(0, 0, 0, 0.2)',
    margin: 12,
  },
  textContainer: {
    marginTop: 8,
    width: '100%',
    maxWidth: 650,
    textAlign: 'left',
  },
});

export default useStyles;
