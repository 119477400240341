import { makeStyles } from '@material-ui/styles';
import { ORANGE } from '../../constants/colors';
import { PHONE_MAX, TABLET_MAX } from '../../constants/breakpoints';

const useStyles = makeStyles({
  subheader: {
    background: 'white',
    borderRadius: 16,
    border: `2px solid ${ORANGE}`,
    width: '60%',
    margin: '0 auto',
    padding: 16,

    [PHONE_MAX]: {
      width: '90%',
    },
  },
  subheaderAboutText: {
    fontSize: '0.9em',
  },
  iconContainer: {
    margin: 16,
    display: 'flex',
    flexFlow: 'row wrap',
    justifyContent: 'space-around',
    alignItems: 'center',
  },
  textIconContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '4px 6px',
    [PHONE_MAX]: {
      padding: '0.5em',
    },
  },
  text: {
    fontSize: '1em',
    color: '#666',
    fontWeight: 'bold',
  },
  icon: {
    height: '3rem',
    fill: ORANGE,
    marginBottom: 12,
    [TABLET_MAX]: {
      height: '2.5rem',
    },
  },
});

export default useStyles;
