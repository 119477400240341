import React from 'react';
import useStyles from './offer-images.style';
import Img from 'gatsby-image';
import { useStaticQuery, graphql } from 'gatsby';

const useGallery = () => {
  const data = useStaticQuery(graphql`
    query {
      allFile(
        filter: {
          extension: { regex: "/(jpg)|(png)|(jpeg)/" }
          relativeDirectory: { eq: "carousel" }
        }
        sort: { order: ASC, fields: name }
      ) {
        nodes {
          id
          name
          publicURL
          childImageSharp {
            fluid(maxWidth: 960) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  `);

  return data.allFile.nodes.map((node) => ({
    ...node.childImageSharp,
    id: node.id,
    name: node.name,
    publicURL: node.publicURL,
  }));
};

const headings = [
  'Montaż grzejników',
  'Montaż ogrzewania grzejnikowego',
  'Montaż ogrzewania podłogowego',
  'Przykładowa kotłownia',
  'Próba szczelności',
];

const texts = [
  'Dobieramy i montujemy grzejniki różnych producentów.',
  'Przykładowa instalacja ogrzewania grzejnikowego prowadzona pod posadzką.',
  'Ogrzewanie podłogowe wykonujemy na komponentach firmy Tweetop.',
  'Pomagamy dobrać i zamontować w atrakcyjnych cenach urządzenia takich producentów jak Junkers, Bosch i Daikin.',
  'Wykonujemy próby szczelności zmontowanych przez nas instalacji wod-kan, ogrzewania jak i gazowych.',
];

const Image = ({ img, className, alt }) => (
  <Img className={className} fluid={img.fluid} alt={alt} />
);
const Text = ({ index, classes }) => (
  <div className={classes.textContainer}>
    <h3>{headings[index]}</h3>
    <p className={classes.text}>{texts[index]}</p>
  </div>
);

const OfferImages = () => {
  const classes = useStyles();
  const images = useGallery();

  return (
    <div className={classes.root}>
      <section className={classes.section}>
        {images.map((image, index) => (
          <div className={classes.textImageContainer} key={index}>
            <Image img={image} className={classes.image} alt={headings[index]} />
            <Text index={index} classes={classes} />
          </div>
        ))}
      </section>
    </div>
  );
};

export default OfferImages;
