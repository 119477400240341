import React from 'react';
import ExperienceIcon from '../../images/icons/solution-strategy.svg';
import ServiceIcon from '../../images/icons/services-ac.png';
import OfferIcon from '../../images/icons/percent.svg';
import useStyles from './subheader.style';

const Subheader = () => {
  const classes = useStyles();

  return (
    <div className={classes.subheader}>
      <h2>O nas</h2>
      <p className={classes.subheaderAboutText}>
        Jesteśmy firmą świadczącą usługi instalacyjne od 2008 roku. W tym czasie wykonaliśmy wiele
        prac instalacyjnych <strong>na terenie Łodzi i województwa łódzkiego</strong>.
        <br />
        Posiadamy <strong>aktualne uprawnienia energetyczne i gazowe</strong>. Współpracujemy z
        firmą Junkers-Bosch i posiadamy autoryzację na montaż urządzeń.
        <br />
        Prowadzimy również <strong>próby szczelności</strong>.
      </p>

      <div className={classes.iconContainer}>
        <div className={classes.textIconContainer}>
          <img src={ExperienceIcon} className={classes.icon} alt="Doświadczenie" />
          <span className={classes.text}>12 lat doświadczenia</span>
        </div>
        <div className={classes.textIconContainer}>
          <img src={ServiceIcon} className={classes.icon} alt="Obsługa" />
          <span className={classes.text}>Profesjonalna i kompleksowa obsługa</span>
        </div>
        <div className={classes.textIconContainer}>
          <img src={OfferIcon} className={classes.icon} alt="Oferta" />
          <span className={classes.text}>Atrakcyjne oferty</span>
        </div>
      </div>
    </div>
  );
};

export default Subheader;
