import { makeStyles } from '@material-ui/styles';
import { ORANGE } from '../constants/colors';
import { PHONE_MAX, TABLET_MAX } from '../constants/breakpoints';

const useStyles = makeStyles({
  root: {
    width: '80vw',
    margin: '0 auto',
    [TABLET_MAX]: {
      width: '80vw',
    },
    [PHONE_MAX]: {
      width: '90vw',
    },
  },
  textIconContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  text: {
    fontSize: '1em',
    color: '#666',
    fontWeight: 'bold',
  },
  icon: {
    width: 120,
    height: 70,
    fill: ORANGE,
    marginBottom: 12,
  },
});

export default useStyles;
