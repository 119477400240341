import React from 'react';
import SEO from '../components/seo';
import Layout from '../components/layout';
import useStyles from '../pages-styles/oferta.style';
import Subheader from '../components/Oferta/subheader';
import OfferImages from '../components/Oferta/offer-images';

const Oferta = () => {
  const classes = useStyles();
  return (
    <Layout>
      <SEO title="Oferta" />
      <div className={classes.root}>
        <Subheader />
        <OfferImages />
      </div>
    </Layout>
  );
};

export default Oferta;
